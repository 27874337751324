<template>
  <StatusIndicator :label="label" :status="status" />
</template>

<script>
import StatusIndicator from "@/engines/common/components/StatusIndicator";

export default {
  props: {
    data: Object,
  },
  components: {
    StatusIndicator,
  },
  computed: {
    status() {
      return this.data.status;
    },
    label() {
      return this.$t(`dsr.columns.${this.status}`);
    },
  },
};
</script>
