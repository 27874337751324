<template>
  <div v-if="isDsrActivated" class="dsr-main">
    <DgSidebarMenu
      data-testId="dsr-dashboard-sidebar"
      @sidebarAction="collapseMenu"
      :sidebarMenuVisibility="sidebarMenuVisibility"
      :items="sidebarItems"
    />
    <main class="dg-main">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </main>
  </div>
  <div v-else>
    <UnavailableFeature />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import UnavailableFeature from "@/engines/common/components/UnavailableFeature";

export default {
  name: "DSR-Layout",
  components: {
    UnavailableFeature,
  },
  methods: {
    collapseMenu() {
      this.sidebarMenuVisibility = !this.sidebarMenuVisibility;
    },
  },
  computed: {
    ...mapGetters(["isCustomerFeatureActivated"]),
    isDsrActivated() {
      return this.isCustomerFeatureActivated("data_subject_requests");
    },
    sidebarItems() {
      const isDSRDashboard = this.$route.name === "DSRDashboard";
      const isDSRSettings = this.$route.name === "DSRSettings";
      const { status = "all" } = this.$route.query;
      const sidebarOptions = [
        {
          title: this.$t("dsr.sidebar.dsr_dashboard"),
          type: "header",
          state: isDSRDashboard && status === "all" ? "active" : "",
          class: "dsr-icon",
          link: { name: "DSRDashboard", query: { status: "all" } },
        },
        {
          title: this.$t("dsr.sidebar.open_dsr"),
          type: "parent",
          icon: "",
          state: isDSRDashboard && status === "open" ? "active" : "",
          icon_bg_color: "",
          link: { name: "DSRDashboard", query: { status: "open" } },
        },
        {
          title: this.$t("dsr.sidebar.closed_dsr"),
          type: "parent",
          icon: "",
          state: isDSRDashboard && status === "closed" ? "active" : "",
          icon_bg_color: "",
          link: {
            name: "DSRDashboard",
            query: { status: "closed" },
          },
        },
      ];
      const settingItemRequiredPermissions = [
        { action: "view", feature: "dsr_settings" },
        { action: "view", feature: "dsr_addon_settings" },
      ];

      if (this.$can("view", settingItemRequiredPermissions)) {
        sidebarOptions.push({
          title: this.$t("dsr.sidebar.dsr_settings"),
          type: "parent",
          icon: "",
          state: isDSRSettings ? "active" : "",
          icon_bg_color: "",
          link: { name: "DSRSettings" },
        });
      }
      return sidebarOptions;
    },
    ...mapState({
      sidebarMenuVisibility: state => state.menu.sidebarMenuVisibility,
    }),
  },
};
</script>

<style scoped lang="scss">
.dg-main {
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 1140px;

  > div {
    // max-width: 1190px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
  }
}
.dsr-icon > a.menu-items__title {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: 11px;
    bottom: 0;
    margin: auto;
    background-image: url(./images/dashboard-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    border-radius: 0;
    border: none;
  }
}

.dsr-main {
  display: flex;
}
</style>
