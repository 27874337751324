<template>
  <div class="main-panel">
    <p class="main-panel__topic">{{ $t("unavailable_feature.topic") }}</p>
    <i18n :path="'unavailable_feature.description'" tag="p">
      <a class="main-panel__description" :href="getHomeUrl"> {{ $t("unavailable_feature.link_label") }}</a>
    </i18n>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["companyData"]),
    getHomeUrl() {
      return this.companyData.navigation_link.find(item => item.title === "Home").link;
    },
  },
};
</script>

<style scoped lang="scss">
.main-panel {
  margin: auto;
  margin-top: 3%;
  padding: 7%;
  width: 65%;
  color: $dark;
  font-family: $averta;
  font-size: 16px;

  &__topic {
    font-size: 30px;
  }
  &__description {
    color: $red;
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
