<template>
  <div :class="`dg-status-indicator status-${status}`">{{ label }}</div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-status-indicator {
  position: relative;
  margin-left: 12px;
  padding-left: 12px;
  &:before {
    content: "";
    position: absolute;
    left: -12px;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    bottom: 0;
    margin: auto;
  }
  &.status-pending:before {
    background-color: #fa9334;
  }
  &.status-active:before {
    background-color: #5bc88d;
  }
  &.status-finished:before {
    background-color: #7e93a7;
  }
  &.status-failed:before {
    background-color: #36b2e8;
  }
}
</style>
