<template>
  <div>
    <template v-if="companyData && companyData.main_contact_dpo">
      <DgDpoIntro :dpo="companyData && companyData.main_contact_dpo" :title="$t('dsr.welcome')">
        <template v-slot:desc>
          <div v-html="companyData.main_contact_dpo.dpo_description"></div>
        </template>
      </DgDpoIntro>
    </template>
    <div class="prT">
      <DgPanel>
        <template #header>
          <div class="dsr-dashboard__header">
            <div class="dsr-dashboard__header__left">
              <h4 class="dsr-dashboard__header__title">{{ $t("dsr.dashboard.title") }}</h4>
            </div>
            <div class="dsr-dashboard__header__right">
              <dg-button v-can:add="requiredPermissions" @click="createClick">
                {{ $t("dsr.createLabel") }}
              </dg-button>
            </div>
          </div>
        </template>
        <template #default>
          <dg-input class="dsr-dashboard__search" :attrs="searchAttrs" inputType="simple" @keyup.enter="onSearch" />
          <div class="dsr-dashboard__table_container">
            <div class="dsr-dashboard__spinner" v-if="loading">
              <dg-spinner variant="dark" />
            </div>
            <dg-table
              v-can:view="requiredPermissions"
              v-if="Boolean(modifiedData.length)"
              data-testId="dsr-dashboard-table"
              :pagination="paginationDS"
              :columns="columnData"
              :rows="modifiedData"
              @pageClick="pageClick"
              @onSort="onColumnSort"
            />
            <div class="dsr-dashboard__not-found" v-else>No Data Subject Requests found.</div>
          </div>
        </template>
      </DgPanel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Structural } from "@/dgui-customer-components/";
import DSRDetailsLink from "../components/DSRDashboard/DSRDetailsLink";
import DSRListItemStatus from "../components/DSRDashboard/DSRListItemStatus";
import JSONAPI from "jsonapi-serializer";

export default {
  name: "DSR-Dashboard",
  components: {
    DgPanel: Structural.DgPanel,
    DgDpoIntro: Structural.DgDpointro,
  },
  data() {
    const { q = "" } = this.$route.query;
    return {
      columns: [
        "email",
        "subject_name",
        "subject_relation",
        "data_subject_request_type_name",
        "created_at",
        "deadline",
        "status",
      ],
      hideColumns: [0],
      filter: this.$route.query.status || null,
      loading: true,
      pagination: {},
      dsrList: [],
      sortingDetails: null,
      searchAttrs: {
        name: "searchContract",
        placeholder: this.$t("dsr.dashboard.search_label"),
        value: q,
      },
    };
  },
  computed: {
    ...mapGetters(["companyData"]),
    modifiedData() {
      let table = this.dsrList.map(item => {
        // destructing all the required values ignoring the rest
        const {
          id,
          email,
          subject_name,
          subject_relation,
          created_at,
          deadline,
          data_subject_request_type_name,
          status,
        } = item;

        // rearranging the keys in the order as per design
        return {
          id,
          email,
          subject_name,
          subject_relation,
          data_subject_request_type_name,
          created_at: formatDate(created_at),
          deadline: formatDate(deadline),
          status,
        };
      });
      return table;
    },
    columnData() {
      return [
        { title: this.$t("dsr.columns.email"), field: "email", width: "18", sortable: true },
        { title: this.$t("dsr.columns.subject_name"), field: "subject_name", width: "18", sortable: true },
        { title: this.$t("dsr.columns.subject_relation"), field: "subject_relation", sortable: true },
        {
          title: this.$t("dsr.columns.data_subject_request_type_name"),
          field: "data_subject_request_type_name",
          sortable: true,
        },
        { title: this.$t("dsr.columns.created_at"), field: "created_at", sortable: true },
        { title: this.$t("dsr.columns.deadline"), field: "deadline", sortable: true },
        {
          title: this.$t("dsr.columns.status"),
          field: "status",
          sortable: true,
          render: DSRListItemStatus,
        },
        { title: "", field: "", width: "8", render: DSRDetailsLink },
      ];
    },
    paginationDS() {
      return {
        pageSize: this.pagination.per_page,
        totalPages: this.pagination.total_pages,
        defaultPage: this.pagination.current_page,
        totalCount: this.pagination.total_count,
        showResultText: this.$t("dsr.dashboard.show_results_text"),
      };
    },
    requiredPermissions() {
      return ["data_subject_requests", "addon_data_subject_requests"];
    },
  },
  watch: {
    "$route.query"(n, o) {
      this.fetchData(n);
    },
  },
  channels: {
    DataSubjectRequestsChannel: {
      received(data) {
        if (data.action_type === "create") {
          new JSONAPI.Deserializer({
            keyForAttribute: "underscore_case",
          })
            .deserialize(data)
            .then(deserialized => {
              this.dsrList = [deserialized, ...this.dsrList];
              this.pagination.total_count++;
              this.pagination.total_pages = Math.ceil(this.pagination.total_count / this.pagination.per_page);
              if (this.pagination.current_page !== 1) {
                this.$dgToast.show({
                  type: "info",
                  title: "New DSR",
                  content: this.$t("dsr.details.newDsr"),
                });
              }
            });
        }
      },
    },
  },
  created() {
    this.$cable.subscribe({
      channel: "DataSubjectRequestsChannel",
      customer_id: this.$route.params.id,
      locale: this.$route.params.lang,
    });
    this.fetchData(this.$route.query.page);
  },
  methods: {
    pageClick(page) {
      this.updateQuery({ page });
    },
    onColumnSort({ column, sortOrder }) {
      let columnName = column;
      this.updateQuery({ sortCol: columnName, sortOrder });
    },
    onSearch() {
      this.updateQuery({ q: this.searchAttrs.value });
    },
    updateQuery(queryParams) {
      const query = {};
      const queryKeys = ["q", "page", "status", "sortCol", "sortOrder"];
      queryKeys.forEach(queryKey => {
        if (queryParams.hasOwnProperty(queryKey)) {
          query[queryKey] = queryParams[queryKey];
        } else if (this.$route.query.hasOwnProperty(queryKey)) {
          query[queryKey] = this.$route.query[queryKey];
        }
      });
      this.$router.push({
        name: "DSRDashboard",
        query,
      });
    },
    fetchData() {
      const { page = 1, status = "", sortCol = "", sortOrder = "", q = "" } = this.$route.query;
      const endpoint = `/api1/v2/customers/${this.$route.params.id}/data_subject_requests?page=${page}&per=10`;
      let url = ["open", "closed"].includes(status) ? `${endpoint}&by_status=${status}` : endpoint;
      // appending sorting info to API endpoint
      // until migrating to rails 6, we send a default sorting parameter
      url += `&sorted[column]=${sortCol || "default"}`;
      if (sortOrder) {
        url += `&sorted[direction]=${sortOrder}`;
      }
      // appending search filter info to API endpoint
      if (q) {
        url += `&search=${q}`;
      }
      this.loading = true;
      this.$http
        .get(url)
        .then(response => {
          this.pagination = response.data.pagination;
          this.dsrList = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.apiCatch(error);
        });
    },
    rowClick(row) {
      this.$router.push({
        name: "DSRDetails",
        params: {
          dsrid: row.id,
        },
      });
    },
    createClick() {
      this.$router.push({ name: "DSRCreate" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dsr-dashboard {
  &__header {
    display: flex;
    justify-content: space-between;

    &__left,
    &__right {
      display: flex;
      align-items: center;
    }

    &__title {
      font-size: 1.5rem;
      position: relative;
      margin-bottom: 0;

      &::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: #c63040;
        display: block;
        bottom: -15px;
        left: 0;
      }
    }
  }

  &__search {
    margin-bottom: 12px;
  }

  &__table_container {
    position: relative;
  }

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.6;

    .dg-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  &__not-found {
    color: rgba(0, 0, 0, 0.54);
    font-style: italic;
  }
}
</style>
